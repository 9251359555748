<template>
  <v-container grid-list-xs fill-height>
    <v-row>
      <v-col align="center" justify="center">
        <h1 class="text-h1">vividit</h1>
        <h2 class="font-weight-light">
          Moderní IT služby pro jednotlivce i malé firmy
        </h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Vividit',
};
</script>

<style>
</style>
